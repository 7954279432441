var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "free-tools"
  }, [_vm.freeToolsAvailable ? _c('sygni-loader', {
    attrs: {
      "isLoading": _vm.xmlLoading
    }
  }) : _vm._e(), _c('GenproxPageHeading', {
    attrs: {
      "title": "Tools",
      "description": "Quick access to tools for viewing and analyzing financial reports."
    }
  }), _c('div', {
    staticClass: "free-tools__container"
  }, [_c('div', {
    staticClass: "free-tools__box"
  }, [_c('FreeToolsBox', {
    attrs: {
      "description": 'Upload your DATMAN or DATIF XML files for KNF reporting to review their content in readable form.',
      "title": 'XML Reader for AIF Regulatory Reporting',
      "href": '/tools/knf-xml-preview',
      "uploadId": 'fileUpload1',
      "urlType": 'knf'
    },
    on: {
      "setLoading": _vm.setLoading
    }
  })], 1), _c('div', {
    staticClass: "free-tools__box"
  }, [_c('FreeToolsBox', {
    attrs: {
      "description": 'Upload your Financial Statement in XML format to review its content in readable form.',
      "title": 'XML Reader for Financial Statements',
      "href": '/tools/xml-preview',
      "uploadId": 'fileUpload2',
      "urlType": 'xml'
    },
    on: {
      "setLoading": _vm.setLoading
    }
  })], 1), _c('div', {
    staticClass: "free-tools__box free-tools__box--placeholder"
  }, [_c('FreeToolsBox', {
    attrs: {
      "isPlaceholder": true
    }
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }